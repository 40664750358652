import React, { FC, useRef } from 'react';
import { graphql } from 'gatsby';
import { GatsbySeo } from 'gatsby-plugin-next-seo/lib';
import { LogoJsonLd } from 'gatsby-plugin-next-seo';

import { useIntl } from 'gatsby-plugin-intl';

import { getLink } from '@helpers/linking.helper';
import { PageType } from '@constants/pageType.constants';

import { AllWpGuaranteeProducts } from '../../types/guarantee.types';

import { Container } from '@components/container/Container.component';
import { Form } from '@components/form/Form.component';
import { ContactData } from '@components/contact-data/ContactData.component';

import Thumbnail from '@assets/thumbnails/avionaut_thumbnail.jpg';

import s from './Contact.module.scss';

interface IContactTemplate {
  data: {
    allWpGuaranteeProduct: AllWpGuaranteeProducts;
  }
}

const ContactTemplate: FC<IContactTemplate> = (props) => {
  const {
    data: {
      allWpGuaranteeProduct,
    },
  } = props;
  
  const intl = useIntl();

  const outsideRef = useRef<HTMLDivElement>(null);
  
  const edges = [...allWpGuaranteeProduct.edges];

  const prepareGuaranteeProductList = () => {
    const guaranteeProductList = edges.map((product) => {
      return product.node.guaranteeProductGroup.product[0].productMetaData.productname;
    });

    return guaranteeProductList;
  };

  return (
    <>
      <LogoJsonLd
        logo={'https://avionaut.com/avionaut.svg'}
        url={`https://avionaut.com${getLink(intl.locale, PageType.START)}`}
      />
      <GatsbySeo
        htmlAttributes={{
          language: intl.locale
        }}
        language={intl.locale}
        languageAlternates={[
          {
            hrefLang: 'x-default',
            href: `https://avionaut.com${getLink(intl.locale, PageType.CONTACT)}/`,
          },
          {
            hrefLang: 'en',
            href: `https://avionaut.com${getLink('en', PageType.CONTACT)}/`,
          },
          {
            hrefLang: 'pl',
            href: `https://avionaut.com${getLink('pl', PageType.CONTACT)}/`,
          },
          {
            hrefLang: 'de',
            href: `https://avionaut.com${getLink('de', PageType.CONTACT)}/`,
          },
          {
            hrefLang: 'cz',
            href: `https://avionaut.com${getLink('cz', PageType.CONTACT)}/`,
          },
          {
            hrefLang: 'hr',
            href: `https://avionaut.com${getLink('hr', PageType.CONTACT)}/`,
          },
          {
            hrefLang: 'es',
            href: `https://avionaut.com${getLink('es', PageType.CONTACT)}/`,
          },
          {
            hrefLang: 'fr',
            href: `https://avionaut.com${getLink('fr', PageType.CONTACT)}/`,
          },
        ]}
        canonical={`https://avionaut.com${getLink(intl.locale, PageType.CONTACT)}/`}
        title={`Avionaut - ${intl.formatMessage({ id: 'metaTitle__contact' })}`}
        description={intl.formatMessage({ id: 'metaDescription__contact' })}
        metaTags={[
          {
            property: 'thumbnail',
            content: Thumbnail,
          },
          {
            property: 'og:title',
            content: intl.formatMessage({ id: 'metaTitle__contact' }),
          },
          {
            property: 'og:description',
            content: intl.formatMessage({ id: 'metaDescription__contact' }),
          },
          {
            property: 'twitter:title',
            content: intl.formatMessage({ id: 'metaTitle__contact' }),
          },
          {
            property: 'twitter:description',
            content: intl.formatMessage({ id: 'metaDescription__contact' }),
          },
        ]}
      />
      <Container>
        <div className={s.contact} ref={outsideRef}>
          <div className={s.contact__content}>
            <div className={s.contact__data}>
              <ContactData
                header={intl.formatMessage({ id: 'contact__header' })}
                searchHeader={intl.formatMessage({ id: 'storeSearch__header' })}
                searchText={intl.formatMessage({ id: 'contact__searchPlaceholder' })}
              />
            </div>
            <div className={s.contact__form}>
              <Form
                header={intl.formatMessage({ id: 'contact__formHeader' })}
                name={intl.formatMessage({ id: 'contact__formNamePlaceholder' })}
                email={intl.formatMessage({ id: 'contact__formEmailPlaceholder' })}
                textarea={intl.formatMessage({ id: 'contact__formMessagePlaceholder' })}
                checkboxLabelPart1={intl.formatMessage({ id: 'contact__formCheckboxLabel' })}
                checkboxLabelPart2={intl.formatMessage({ id: 'contact__formCheckboxLabelGDPR' })}
                outsideRef={outsideRef}
                warrantyProductsList={prepareGuaranteeProductList()}
              />
            </div>
          </div>
        </div>
      </Container>
    </>
  );
};
export default ContactTemplate;

export const query = graphql`
  query GetGuaranteeProducts {
    allWpGuaranteeProduct {
      edges {
        node {
          guaranteeProductGroup {
            product {
              ... on WpProduct {
                productMetaData {
                  productname
                }
              }
            }
          }
        }
      }
    }
  }
`;

