import { navigate } from 'gatsby';
import { RefObject } from 'react';
import { gsap } from 'gsap';
import { FetchResponse } from '../../types';
import { getLink } from '@helpers/linking.helper';
import { PageType } from '@constants/pageType.constants';

const PROD_URL = 'https://jrd0bzfgai.execute-api.eu-west-1.amazonaws.com/PROD/send';

export const manageVisibilityOfAdditionalField = (isOpen:boolean, refAdditionalFields:  RefObject<HTMLDivElement>) => {

  if (refAdditionalFields.current !== null) {
    const tl = gsap.timeline();
    tl.to(refAdditionalFields.current, {
      autoAlpha: isOpen ? 1 :0,
      height: isOpen ? 'auto': 0,
      duration: isOpen ? .1 : .2,
      ease:  isOpen ? 'power3.in' : 'power3.out',
      onComplete: () => {
        tl.kill();
      }
    });
  }
}

export const sendEmail = async (content: string, setIsLoading: any, locale: string) => {
  const myUrl = PROD_URL;

  const responseData: FetchResponse = {
    code: null,
    isOk: false,
  };

  await fetch(myUrl, {
    method: 'POST',
    body: content,
    headers: { 'Content-Type': 'application/json' },
  }).then(response => {
    responseData.code = response.status;
    responseData.isOk = response.ok;
    return response.json();
  })
    .then(() => {
      navigate(
        getLink(locale, PageType.SENDING_SUCCESS),
      );
    })
    .catch(() => {
      navigate(
        getLink(locale, PageType.SENDING_PROBLEM),
      );
    })
    .finally(() => {
      setIsLoading(false);
    });
};

