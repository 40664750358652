import React, { FC, useEffect, useRef } from 'react';
import GoogleMapReact, { BootstrapURLKeys } from 'google-map-react';

import { useGTMDispatch } from '@elgorditosalsero/react-gtm-hook';
import { useIntl } from 'gatsby-plugin-intl';

import { enableEnterKey } from '@helpers/autocomplete.helper';

import Search from '../../assets/svg/search.svg';

import s from './SearchEngine.module.scss';



interface ISearchEngine {
  filedText: string;
  locale: string;
  shopsURL: string;
  isCalledFromProductList?:boolean
  higher?: boolean;
}

export const SearchEngine: FC<ISearchEngine> = (props) => {

  const {
    filedText,
    locale,
    shopsURL,
    isCalledFromProductList,
    higher,
  } = props;

  const refSearch = useRef<HTMLInputElement>(null);
  const refAutocomplete = useRef<any>(null);
  const sendDataToGTM = useGTMDispatch();
  const intl = useIntl();

  const bootstrapUrlKeys: BootstrapURLKeys = {
    key: 'AIzaSyB8k4KJqwSDuWHQxpi_6j3_wOaFOsiSBzY',
    libraries: ['places', 'geometry'],
    region: locale,
    language: locale,
  };

  const { googleMapLoader } = GoogleMapReact as any;

  const redirectToMapAndChoseLocation = (): void => {
    const place = refAutocomplete.current?.getPlace();

    sendDataToGTM({
      'event': 'search-engine',
      'event_category': `${isCalledFromProductList ? 'product list' : 'contact'}`,
      'event_label': `${place.address_components[0] ? place.address_components[0].long_name: 'no place'}`,
      'event_value': `${place.address_components[3] ? place.address_components[3].short_name: 'no country'}`,
      'event_language': intl.locale,
    })

    if (place) {
      if (place.hasOwnProperty('address_components') && place.hasOwnProperty('geometry')) {
        const lat = place.geometry.location.lat();
        const lng = place.geometry.location.lng();
        const location = place.address_components[0].long_name;

        if (typeof window !== `undefined`) {
          window.location.href = `${shopsURL}?w=${lat}&h=${lng}&location=${location}`;
        }
      }
    }
  };

  useEffect(() => {
    googleMapLoader(bootstrapUrlKeys)
      .then((maps: any) => {
        refAutocomplete.current = new maps.places.Autocomplete(refSearch.current, { types: ['(cities)'] });
        refAutocomplete.current?.setFields(['address_components', 'geometry']);
        refAutocomplete.current?.addListener('place_changed', () => redirectToMapAndChoseLocation());
      });
  }, [googleMapLoader]);

  useEffect(() => {
    if (refSearch.current !== null) enableEnterKey(refSearch.current);
  }, [refSearch.current]);

  return (
    <div className={s.searchEngine}>
      <input className={`${s.searchEngine__field} ${higher ? s.higher : ''}`} type={'text'}
             placeholder={filedText} ref={refSearch} />
      <div className={`${s.searchEngine__icon} ${higher ? s.higher : ''}`}>
        <Search />
      </div>
    </div>
  );
};
