import React, { FC, FormEvent, RefObject, useEffect, useRef, useState } from 'react';
import { useIntl } from 'gatsby-plugin-intl';

import { manageVisibilityOfAdditionalField, sendEmail } from './Form.helper';

import { Button } from '../button/Button';
import { Input } from '../input/Input.component';
import { MultilingualLink } from '../multilingual-link/MultilingualLink.component';
import { CustomSelect } from '../custom-select/CustomSelect.component';
import { Loader } from '../loader/Loader.component';

import { PageType } from '@constants/pageType.constants';

import s from './Form.module.scss';

const SERVICE_TOPIC = 0;

interface IForm {
  header: string
  name: string
  email: string
  textarea: string
  checkboxLabelPart1: string,
  checkboxLabelPart2: string,
  outsideRef: RefObject<HTMLDivElement>,
  warrantyProductsList: string[],
}

export const Form: FC<IForm> = ({
                                  header,
                                  name,
                                  email,
                                  textarea,
                                  checkboxLabelPart1,
                                  checkboxLabelPart2,
                                  outsideRef,
                                  warrantyProductsList
                                }) => {
  const intl = useIntl();

  const [selected, setCheckboxSelected] = useState<boolean>(false);
  const [userName, setUserName] = useState<string>();
  const [userEmail, setUserEmail] = useState<string>();
  const [productSerialNumber, setProductSerialNumber] = useState<string>();
  const [product, setProduct] = useState<string>();
  const [userMessage, setUserMessage] = useState<string>();
  const [isTopicDropdownVisible, setIsTopicDropdownVisible] = useState<boolean>(false);
  const [isProductDropdownVisible, setIsProductDropdownVisible] = useState<boolean>(false);
  const [topic, setTopic] = useState<number>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isSubjectWrong, setIsSubjectWrong] = useState<boolean>(false);

  const refSubject = useRef<HTMLDivElement>(null);
  const refAdditionalFields = useRef<HTMLDivElement>(null);

  const subjects = [
    intl.formatMessage({ id: 'subject_service' }),
    intl.formatMessage({ id: 'subject_clientService' }),
    intl.formatMessage({ id: 'subject_collaboration' }),
    intl.formatMessage({ id: 'subject_marketing' }),
  ];

  const handleDropdownClose = () => {
    setIsTopicDropdownVisible(false);
    setIsProductDropdownVisible(false);
  };

  const getSubject = (selectedTopic: number | undefined) => {
    const subject = selectedTopic ? subjects.indexOf(selectedTopic.toString()) : -1;

    if (subject < 0) {
      refSubject.current?.children[1].setCustomValidity(intl.formatMessage({ id: 'guarantee__formUniqueSn' }));
      setIsSubjectWrong(true);
    } else {
      refSubject.current?.children[1].setCustomValidity('');
      setIsSubjectWrong(false);
    }

    return subject;
  };
  
  const selectTopic = (topic: number) => {
    const subject = getSubject(topic);

    if (subject >= 0) {
      setTopic(topic);
      setIsTopicDropdownVisible(false);
      setIsSubjectWrong(false);
      manageVisibilityOfAdditionalField(subject === SERVICE_TOPIC, refAdditionalFields);
    }
  };

  const handleFormSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setIsLoading(true);
    let form = [];
    let data = [];

    if (typeof window !== 'undefined' && typeof document !== 'undefined') {
      form = document.getElementById('contact-form')!;
      data = new FormData(form);
    }

    const clientSubject = getSubject(topic);

    if (clientSubject < 0) {
      setIsLoading(false);
      return;
    }
    
    let finallContent;

    finallContent = {
      subject: clientSubject,
      message: data.get('message') as string,
      senderMail: data.get('email') as string,
      senderName: data.get('name') as string,
      locale: intl.locale,
    };
    
    if(product) {
      finallContent = {
        ...finallContent,
        product,
      }
    }

    if(data.get('serial-number')) {
      finallContent = {
        ...finallContent,
        serialNumber: data.get('serial-number') as string
      }
    }
    
    const content = JSON.stringify(finallContent)

    sendEmail(content, setIsLoading, intl.locale);
  };

  useEffect(() => {
    outsideRef.current?.addEventListener('click', handleDropdownClose, false);
    return () => {
      outsideRef.current?.removeEventListener('click', handleDropdownClose, false);
    };
  }, [outsideRef.current]);

  return (
    <div className={s.form}>
      <Loader isLoading={isLoading}/>
      <div className={s.form__header}>
        <h2 className={s.form__headerText}>
          {header}
        </h2>
      </div>
      <form
        className={s.form__body}
        onSubmit={(event) => handleFormSubmit(event)}
        id={'contact-form'}
        autoComplete="off"
      >
        <Input
          isFull={userName}
          placeholder={name}
          name={'name'}
          type={'text'}
          value={userName}
          changeMethod={setUserName}
          required
        />
        <Input
          isFull={userEmail}
          placeholder={email}
          name={'email'}
          type={'email'}
          value={userEmail}
          changeMethod={setUserEmail}
          required
        />
        <div className={s.form__customSelect}>
          <CustomSelect
            placeholder={intl.formatMessage({ id: 'contact__formSubject' })}
            range={subjects}
            visible={isTopicDropdownVisible}
            handleClick={() => setIsTopicDropdownVisible(true)}
            value={topic}
            handleSelect={selectTopic}
            isGuaranteeForm
            required
            reference={refSubject}
            isValueWrong={isSubjectWrong} 
          />
        </div>
          <div className={s.form__additionalFields} ref={refAdditionalFields}>
            <p className={s.form__additionalFields__text}>{intl.formatMessage({ id: 'contact__incentive' })}</p>
            <Input
              isFull={productSerialNumber}
              placeholder={intl.formatMessage({ id: 'guarantee__formSerialNumber' })}
              name={'serial-number'}
              type={'text'}
              value={productSerialNumber}
              changeMethod={setProductSerialNumber}
            />
            <div className={s.form__customSelect}>
              <CustomSelect
                placeholder={intl.formatMessage({ id: 'guarantee__formProduct' })}
                range={warrantyProductsList}
                visible={isProductDropdownVisible}
                handleClick={() => setIsProductDropdownVisible(true)}
                value={product}
                handleSelect={setProduct}
                isGuaranteeForm
              />
            </div>
          </div>
        <div className={s.form__field}>
          <p className={`${s.form__label} ${userMessage ? s.full : ''}`}>{textarea}</p>
          <textarea
            className={s.form__textarea}
            placeholder={textarea}
            name="message"
            required
            value={userMessage}
            onChange={(event) => setUserMessage(event.target.value)}
          />
        </div>
        <div className={s.form__checkbox} onClick={() => setCheckboxSelected(!selected)}>
          <input
            className={`${s.form__checkboxInput} ${selected ? s.selected : ''}`}
            type="checkbox"
            required
            checked={selected}
            onChange={() => null}
          />
          <label className={s.form__checkboxLabel}>
            <p>
              {checkboxLabelPart1}
              <MultilingualLink
                pageType={PageType.PRIVACY_POLICY}
                target="_blank"
                rel="noopener noreferrer"
                className={s.form__link}
              >
                {checkboxLabelPart2}
              </MultilingualLink>
            </p>
          </label>
        </div>
        <div className={s.form__button}>
          <Button>{intl.formatMessage({ id: 'contact__formSubmit' })}</Button>
        </div>
      </form>
    </div>
  );
};
