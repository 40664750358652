export const enableEnterKey = (input: HTMLInputElement) => {

  /* Store original event listener */
  const _addEventListener = input.addEventListener;

  const addEventListenerWrapper = (type: string, listener: any) => {
    if (type === 'keydown') {
      /* Store existing listener function */
      const _listener = listener;
      listener = (event: KeyboardEvent) => {
        /* Simulate a 'down arrow' keypress if no address has been selected */
        const suggestionSelected = document.getElementsByClassName('pac-item-selected').length;
        if (event.key === 'Enter' && !suggestionSelected) {
          const e = new KeyboardEvent('keydown', {
            key: 'ArrowDown',
            code: 'ArrowDown',
            keyCode: 40,
          });
          _listener.apply(input, [e]);
        }
        _listener.apply(input, [event]);
      };
    }
    _addEventListener.apply(input, [type, listener]);
  };

  input.addEventListener = addEventListenerWrapper;
};
