import React, { FC } from 'react';
import { useIntl } from 'gatsby-plugin-intl';
import { StaticImage } from 'gatsby-plugin-image';

import { getLink } from '@helpers/linking.helper';
import { PageType } from '@constants/pageType.constants';

import { SearchEngine } from '../search-engine/SearchEngine.component';

import Pin from '@assets/svg/pin.svg';

import s from './ContactData.module.scss';


interface IContactData {
  header: string
  searchHeader: string
  searchText: string
}

export const ContactData: FC<IContactData> = (props) => {
  const {
    header,
    searchHeader,
    searchText,
  } = props;

  const intl = useIntl();
  
  return (
    <div className={s.contactData}>
      <div className={s.contactData__header}>
        <h1 className={`${s.contactData__headerText} ${s.contactData__headerSection}`}>
          {header}
        </h1>
      </div>
      <div className={s.contactData__dataDetails}>
        <div className={s.contactData__address}>
          <div className={s.contactData__icon}>
            <Pin className={s.contactData__iconSvg} />
          </div>
          <div className={s.contactData__text}>
            Ł. Karwala sp. k.<br />
            Szarlejka<br />
            ul. Łukaszewicza 172<br />
            42-130 Wręczyca Wielka<br />
            POLAND<br />
            PL5742059942
          </div>
        </div>
        <div className={s.contactData__address}>
          <div className={s.contactData__icon}>
            <Pin className={s.contactData__iconSvg} />
          </div>
          <div className={s.contactData__text}>
            AVIOLY LTD<br />
            63/66<br />
            Hatton Garden, Fifth Floor<br />
            London<br />
            EC1N 8LE<br />
            GB401113279
          </div>
        </div>
      </div>
      <div className={s.contactData__search}>
        <StaticImage 
          src={'../../assets/images/contact-search-bg.jpg'} 
          alt={'Black Silesia Map'}
          className={s.contactData__searchBackground} 
          height={245}
        />
        <div className={s.contactData__content}>
          <div className={s.contactData__header}>
            <p className={`${s.contactData__headerText} ${s.contactData__headerSearch}`}>
              {searchHeader}
            </p>
          </div>
          <div className={s.contactData__searchBox}>
            <SearchEngine
              filedText={searchText}
              shopsURL={getLink(intl.locale, PageType.SHOPS)}
              locale={intl.locale}
            />
          </div>
        </div>

      </div>
    </div>
  );
};
